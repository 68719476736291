<template>
  <v-card class="mb-7" elevation="0" outlined tile>
    <v-card-text class="pa-5">
      <div v-if="elementVisible">
        <vue-apex-charts
          type="bar"
          height="400"
          :options="dashboardOptions.length > 0 ? dashboardOptions[0] : {}"
          :series="series"
        ></vue-apex-charts>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'CumplimientoPLXArea',
  props: {
    datos: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    series: [],
    chartOptions: {
      grid: {
        show: true,
        borderColor: 'rgba(0,0,0,.3)',
        strokeDashArray: 2,
        padding: {
          left: 0,
          right: 0,
          bottom: 0
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '60%',
          endingShape: 'flat'
        }
      },
      colors: ['#27A300'],
      fill: {
        type: 'solid',
        opacity: 1
      },
      chart: {
        toolbar: {
          show: true
        },
        sparkline: {
          enabled: false
        }
      },
      title: {
        text: '% DE CUMPLIMIENTO POR ÁREAS DE GH PLANES VS LOGROS',
        align: 'center',
        margin: 5,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '18px',
          fontWeight: 'normal',
          fontFamily: 'Roboto, Helvetica, Arial',
          color: 'rgba(0, 0, 0, 0.8)'
        }
      },
      legend: {
        show: true,
        position: 'top',
        fontSize: '14px',
        fontFamily: 'Roboto, Helvetica, Arial',
        markers: {
          width: 14,
          height: 14,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: undefined,
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0
        },
        labels: {
          colors: '#424242',
          useSeriesColors: false
        },
        itemMargin: {
          horizontal: 15,
          vertical: 5
        },
        onItemHover: {
          highlightDataSeries: true
        },
        onItemClick: {
          toggleDataSeries: true
        }
      },
      dataLabels: {
        enabled: true
      },
      markers: {
        size: 0
      },
      xaxis: {},
      yaxis: {
        show: true,
        labels: {
          style: {
            cssClass: 'text-muted fill-color'
          }
        }
      },
      stroke: {
        curve: 'straight',
        width: 0
      },
      tooltip: {
        theme: 'dark'
      }
    },
    dashboardOptions: [],
    elementVisible: false
  }),
  watch: {
    datos(val) {
      let subareas = [];
      let cumplimientos = [];
      if (val.totaltareasareas != undefined) {
        if (val.totaltareasareas.length > 0) {
          val.totaltareasareas.forEach((element) => {
            if (element.idsubarea != 'GH') {
              cumplimientos.push(element.cumplimiento);
              subareas.push(element.subarea.split(' '));
            }
          });
          this.dashboardOptions.push({
            ...this.chartOptions,
            ...{
              xaxis: {
                type: 'category',
                categories: subareas,
                labels: {
                  style: {
                    fontSize: '10px',
                    fontWeight: 'bolder',
                    cssClass: 'text-muted fill-color'
                  }
                }
              }
            }
          });
          this.series = [
            {
              name: 'CUMPLIMIENTO',
              data: cumplimientos
            }
          ];
        } else {
          this.series = [];
        }
        this.elementVisible = true;
      }
    }
  },
  created() {},
  components: {
    VueApexCharts
  }
};
</script>
